import { useRef, useEffect, useState } from 'react';
import React from "react";
import './Contact.css'

import ContactForm from './ContactForm'

export default function Contact() {
    const titleRef = useRef();

    const [getTitleVisibility,setTitleVisibility] = useState();

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            setTitleVisibility(entries[0].isIntersecting);
        })

        observer.observe(titleRef.current);
    },[])

    return (
        <div className="Contact" id="contact">
            <div id="disclaimer-contact">
                <h1 ref={titleRef} className={`disclaimer-contact-title ${getTitleVisibility ? 'disclaimer-contact-title-appear' : ''}`}>Contact Me!</h1>
                {/* <p>Looking for a piano or voice teacher? In need of a mezzo-soprano vocalist? Send me an email!</p> */}
            </div>
            <ContactForm />
        </div>
    )
}