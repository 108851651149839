import { useRef, useEffect, useState } from 'react';

import React from "react";
import './About.css'
import headshot from '../../Images/NR_10.jpg';

export default function About() {

    //Refs create a reference to an html element that we can access later.
    //  This can be connected by putting ref={NAME_OF_VARIABLE_USED_FOR_REF} inside html element attributes area. 
    const aboutTitle = useRef();
    const aboutImg = useRef();
    const aboutDesc = useRef();

    //UseState allows us to store the state of elements. First element is how you retrieve the state. Second element is how you set the state. Second element exists as a function.

    const [getTVisible,setTVisible] = useState();
    const [getIVisible,setIVisible] = useState();
    const [getDVisible,setDVisible] = useState();

    //Accessing html elements AFTER they render in the html DOM 
    useEffect(() => {
        //Observer listening for entries defined later by observer. Callback retrieves entries when the view changes for those items and gives it back through the variable.
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(e => {
                switch(e.target.className.trim().split(' ')[0]) {
                    case 'about-title':
                        setTVisible(e.isIntersecting);
                        break;
                    case 'about-headshot-image':
                        setIVisible(e.isIntersecting);
                        break;
                    case 'about-desc':
                        setDVisible(e.isIntersecting);
                        break;
                    default:
                        break;
                }
            });
        })

        observer.observe(aboutTitle.current)
        observer.observe(aboutImg.current)
        observer.observe(aboutDesc.current)
    }, [])

    return (
        <div className="About" id="about-me">
            <div className="about-headshot">
                <img ref={aboutImg} className={`about-headshot-image ${getIVisible ? 'fade-in-directional' : ''}`} src={headshot}></img>
            </div>
            <div className={`about-description`}>
                <h1 ref={aboutTitle} className={`about-title ${getTVisible ? 'fade-in-directional' : ''}`}>About Me</h1>
                <div className={`about-divider ${getTVisible ? 'divider-appear' : ''}`}></div>
                <p ref={aboutDesc} className={`about-desc ${getDVisible ? 'fade-in-directional' : ''}`}>My name is Nicole Riddering. I’m a Central Florida based performer, teacher, and singer-songwriter.  Music has been an integral part of my life for as long as I can remember.  At a young age, I began pursuing my love of music by singing at church, taking piano lessons, and performing in choirs, musicals, and talent shows.  In 2021, I graduated with a music performance degree from Rollins College in Winter Park, FL.  I have a strong background in both classical and contemporary solo and ensemble singing and musical theatre performance.  I aspire to always spread joy and evoke emotion through music and inspire my wonderful students to do the same!</p>
            </div>
        </div>
    )
}
