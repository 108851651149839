import { useEffect } from 'react';

import React from "react"
import './Hero.css'

export default function Hero() {
    return (
        <div className="Hero">
            <div className="hero-name-container">
                <div className="hero-name-bottom-wrapper">
                    <div className="hero-bottom-border"></div>
                    <h1 className="hero-title">Nicole Riddering</h1>
                    <div className="hero-bottom-border"></div>
                </div>
            </div>
            <div className="hero-interactive">
                <div className="hero-subtitles">
                    <p className="hero-subtitle-musician">Musician</p>
                    <hr/>
                    <p className="hero-subtitle-teacher">Teacher</p>
                    <hr/>
                    <p className="hero-subtitle-performer">Performer</p>
                </div>
            </div>
        </div>
    )
}
